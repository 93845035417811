import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { Talk, User } from 'API';
import { NoItemIndicator } from 'components/common/NoItemIndicator';
import { UserAvatar } from 'components/common/UserAvatar';
import { useAuth } from 'hooks/use-auth';
import { DateTime } from 'luxon';
import { MdInfo } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { TalkSummary } from '../TalkSummary';

type Props = {
  issueId: string;
  category: string;
  updatedAt?: string | null;
  content?: string | null;
  client?: User | null;
  talks: Talk[];
};

export function IssueSummary(props: Props) {
  const navigate = useNavigate();
  const { user, isMemberOf } = useAuth();

  const updatedAt = () => {
    if (!props.updatedAt) return '';
    const date = DateTime.fromISO(props.updatedAt).toLocal();
    return date.toFormat('MM/dd HH:mm');
  };

  const createTalkSummary = (item: Talk) => {
    return (
      <TalkSummary
        key={`talk-${item.talkId}`}
        issueId={props.issueId}
        talkId={item.talkId}
        user={item.counselor || null}
        lastMessage={item.messages?.items[0]}
        status={item.status ?? 'OPEN'}
      />
    );
  };

  const contentAbbr = () => {
    if (!props.content)
      return (
        <Text as="span" color={'gray.400'}>
          {'(内容がありません)'}
        </Text>
      );
    return props.content;
  };

  const ClientAvatar = () => {
    if (!props.client) {
      return null;
    }
    return (
      <UserAvatar
        imageUrl={props.client?.avatarImageUrl || undefined}
        name={props.client?.name}
      />
    );
  };

  const ClientName = () => {
    const name = props.client?.name ?? user?.name ?? '';
    return (
      <Heading as={'span'} fontSize={'xs'}>
        {name}
      </Heading>
    );
  };

  const ReplyButton = () => {
    return (
      <Flex justifyContent={'flex-end'}>
        <Button onClick={() => navigate(`/issues/${props.issueId}/talks/new`)}>
          返信する
        </Button>
      </Flex>
    );
  };

  const NoTalkElement = () => {
    return (
      <HStack p={2} bg={'glass.500'} alignItems={'flex-start'}>
        <Icon as={MdInfo} color={'blue.500'} />
        <NoItemIndicator
          message={'まだメッセージはありません\n返信をお待ちください'}
        />
      </HStack>
    );
  };

  const TalksBox = () => {
    if (isMemberOf('counselor')) {
      // Counselor モード
      const mine = props.talks?.find((x) => x.counselorId === user?.userId);
      const others = props.talks?.filter((x) => x.counselorId !== user?.userId);
      return (
        <Box w={'full'}>
          {mine ? createTalkSummary(mine) : <ReplyButton />}
          {others?.length ? (
            <Alert mt={2} p={1} status={'info'} bg={'glass.500'}>
              <AlertIcon boxSize={'1rem'} />
              <Text
                fontSize={'xs'}
              >{`他に ${others.length} 名から回答があります`}</Text>
            </Alert>
          ) : null}
        </Box>
      );
    } else {
      // Client モード
      return (
        <Box w={'full'}>
          {props.talks?.length ? (
            props.talks?.map(createTalkSummary)
          ) : (
            <NoTalkElement />
          )}
        </Box>
      );
    }
  };

  return (
    <VStack as="article" alignItems={'stretch'} mb={8}>
      <Stack
        bg={useColorModeValue('glass.400', 'glass.400')}
        boxShadow={'lg'}
        p={4}
        pt={4}
        align={'flex-start'}
        pos={'relative'}
        rounded={'md'}
      >
        <HStack spacing={4} w={'full'} alignItems={'flex-start'}>
          <ClientAvatar />
          <VStack spacing={1} w={'full'} alignItems={'flex-start'}>
            <Flex alignItems={'center'} w={'full'}>
              <ClientName />
              <Text fontSize={'xs'} color={'gray.500'} ml={'auto'}>
                {updatedAt()}
              </Text>
            </Flex>
            <Text
              w={'full'}
              whiteSpace={'pre-line'}
              noOfLines={4}
              fontSize={'sm'}
            >
              {contentAbbr()}
            </Text>
          </VStack>
        </HStack>
        <Box pl={8} pt={2} w={'full'}>
          <TalksBox />
        </Box>
      </Stack>
    </VStack>
  );
}
