import {
  extendTheme, theme as baseTheme, withDefaultColorScheme
} from '@chakra-ui/react';

const Card = {
  // The styles all Cards have in common
  baseStyle: {
    bg: 'glass.500',
    m: 2,
    p: 4,
    rounded: 'lg',
    boxShadow: 'lg',
    backdropFilter: 'blur(8px)',
  },
}

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};
const colors = {
  brand: baseTheme.colors.pink,
  glass: {
    50: 'rgba(255, 255, 255, 0.05)',
    100: 'rgba(255, 255, 255, 0.1)',
    200: 'rgba(255, 255, 255, 0.2)',
    300: 'rgba(255, 255, 255, 0.3)',
    400: 'rgba(255, 255, 255, 0.4)',
    500: 'rgba(255, 255, 255, 0.5)',
    600: 'rgba(255, 255, 255, 0.6)',
    700: 'rgba(255, 255, 255, 0.7)',
    800: 'rgba(255, 255, 255, 0.8)',
    900: 'rgba(255, 255, 255, 0.9)',
  }
};
const components = {
  Spinner: {
    baseStyle: {
      color: 'brand.300',
    }
  },
  Card,
  Input: {
    variants: {
      outline: {
        field: {
          borderColor: 'gray.400',
          bg: 'glass.300',
        }
      }
    },
  },
  Select: {
    variants: {
      outline: {
        field: {
          borderColor: 'gray.400',
          bg: 'glass.300',
        }
      }
    },
  },
  Textarea: {
    variants: {
      outline: {
        borderColor: 'gray.400',
        bg: 'glass.300',
      }
    },
  }
}
const defaultFont = '"Roboto", "Noto Sans JP", sans-serif';
const fonts = {
  body: defaultFont,
  heading: defaultFont,
  mono: 'Menlo, Consolas, monospace',
}

const theme = extendTheme(
  { config, colors, components, fonts },
  withDefaultColorScheme({ colorScheme: 'brand' })
);

export default theme;
