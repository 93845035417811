import {
  Box,
  Grid,
  GridItem,
  IconButton,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { FooterNav } from 'components/navigations/FooterNav';
import React from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { Outlet } from 'react-router-dom';

const MainLayout: React.FC<{
  title: string;
  headerCommands?: React.ReactNode;
  handleBack?: () => void;
  hideFooter?: boolean;
}> = ({ children, title, headerCommands, handleBack, hideFooter }) => {
  return (
    <Grid
      h={'full'}
      templateRows={'auto 1fr auto'}
      gridTemplateAreas="'header' 'main' 'footer'"
      bg={useColorModeValue('gray.100', 'gray.900')}
      bgGradient={'linear(to-r, yellow.100, red.100, pink.100)'}
    >
      <GridItem area={'header'} w={'full'}>
        <Grid
          h={'3rem'}
          px={2}
          templateColumns="auto 1fr auto"
          alignItems={'center'}
          gap={2}
          boxShadow={'lg'}
          bg={'glass.600'}
        >
          {handleBack ? (
            <IconButton
              aria-label="戻る"
              variant={'ghost'}
              onClick={handleBack}
              icon={<MdChevronLeft size={24} />}
            />
          ) : (
            <Box />
          )}
          <GridItem overflow={'hidden'}>
            <Text as="h1" fontSize={'lg'}>
              {title}
            </Text>
          </GridItem>
          <GridItem>{headerCommands}</GridItem>
        </Grid>
      </GridItem>
      <GridItem area={'main'} position="relative" overflowY={'auto'}>
        <Outlet />
        {children}
      </GridItem>
      {hideFooter ? null : (
        <GridItem area={'footer'}>
          <FooterNav />
        </GridItem>
      )}
    </Grid>
  );
};

export default MainLayout;
