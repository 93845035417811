import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { SiteConfigProvider } from 'hooks/use-site-config';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ProvideAuth } from './hooks/use-auth';
import reportWebVitals from './reportWebVitals';
import './styles/global.css';
import theme from './theme';

ReactDOM.render(
  <React.StrictMode>
    <ProvideAuth>
      <SiteConfigProvider>
        <ChakraProvider theme={theme}>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <App />
        </ChakraProvider>
      </SiteConfigProvider>
    </ProvideAuth>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.debug);
