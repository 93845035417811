export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: any;
  AWSDateTime: any;
  AWSEmail: any;
  AWSIPAddress: any;
  AWSJSON: any;
  AWSPhone: any;
  AWSTime: any;
  AWSTimestamp: any;
  AWSURL: any;
  BigInt: any;
  Double: any;
};

export enum AccountCategory {
  Admin = 'ADMIN',
  Client = 'CLIENT',
  Counselor = 'COUNSELOR',
  Organization = 'ORGANIZATION',
  System = 'SYSTEM'
}

export type Config = {
  disabled: Maybe<Scalars['Boolean']>;
  group: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ConfigGroup = {
  items: Array<Config>;
};

export type CreateIssueInput = {
  category: Scalars['String'];
  content: InputMaybe<Scalars['String']>;
  subcategory: InputMaybe<Scalars['String']>;
};

export type DeleteTalkMessageInput = {
  messageId: Scalars['ID'];
  talkId: Scalars['ID'];
};

export type GetTalkMessageConditionInput = {
  talkId: Scalars['ID'];
};

export type Issue = {
  category: Scalars['String'];
  client: Maybe<User>;
  clientId: Scalars['ID'];
  content: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  deleted: Maybe<Scalars['Boolean']>;
  issueId: Scalars['ID'];
  requestCount: Maybe<Scalars['Int']>;
  result: Maybe<Scalars['String']>;
  resultDescription: Maybe<Scalars['String']>;
  status: Maybe<IssueStatus>;
  subcategory: Maybe<Scalars['String']>;
  suggestionCount: Maybe<Scalars['Int']>;
  talkCount: Maybe<Scalars['Int']>;
  talks: Array<Talk>;
  updatedAt: Maybe<Scalars['AWSDateTime']>;
};

export type IssueConnection = {
  items: Array<Issue>;
  nextToken: Maybe<Scalars['String']>;
};

export enum IssueStatus {
  Closed = 'CLOSED',
  Open = 'OPEN'
}

export type ListIssuesByCategoryConditionInput = {
  category: Scalars['String'];
  issueId: InputMaybe<ModelIdInput>;
  subcategory: InputMaybe<Scalars['String']>;
};

export type ListOptionsConditionInput = {
  group: Scalars['String'];
};

export type ListOwnIssuesConditionInput = {
  issueId: InputMaybe<ModelIdInput>;
};

export enum ModelAttributeTypes {
  Null = '_null',
  Binary = 'binary',
  BinarySet = 'binarySet',
  Bool = 'bool',
  List = 'list',
  Map = 'map',
  Number = 'number',
  NumberSet = 'numberSet',
  String = 'string',
  StringSet = 'stringSet'
}

export type ModelBooleanInput = {
  attributeExists: InputMaybe<Scalars['Boolean']>;
  attributeType: InputMaybe<ModelAttributeTypes>;
  eq: InputMaybe<Scalars['Boolean']>;
  ne: InputMaybe<Scalars['Boolean']>;
};

export type ModelFloatInput = {
  attributeExists: InputMaybe<Scalars['Boolean']>;
  attributeType: InputMaybe<ModelAttributeTypes>;
  between: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  eq: InputMaybe<Scalars['Float']>;
  ge: InputMaybe<Scalars['Float']>;
  gt: InputMaybe<Scalars['Float']>;
  le: InputMaybe<Scalars['Float']>;
  lt: InputMaybe<Scalars['Float']>;
  ne: InputMaybe<Scalars['Float']>;
};

export type ModelIdInput = {
  attributeExists: InputMaybe<Scalars['Boolean']>;
  attributeType: InputMaybe<ModelAttributeTypes>;
  beginsWith: InputMaybe<Scalars['ID']>;
  between: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains: InputMaybe<Scalars['ID']>;
  eq: InputMaybe<Scalars['ID']>;
  ge: InputMaybe<Scalars['ID']>;
  gt: InputMaybe<Scalars['ID']>;
  le: InputMaybe<Scalars['ID']>;
  lt: InputMaybe<Scalars['ID']>;
  ne: InputMaybe<Scalars['ID']>;
  notContains: InputMaybe<Scalars['ID']>;
  size: InputMaybe<ModelSizeInput>;
};

export type ModelIntInput = {
  attributeExists: InputMaybe<Scalars['Boolean']>;
  attributeType: InputMaybe<ModelAttributeTypes>;
  between: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq: InputMaybe<Scalars['Int']>;
  ge: InputMaybe<Scalars['Int']>;
  gt: InputMaybe<Scalars['Int']>;
  le: InputMaybe<Scalars['Int']>;
  lt: InputMaybe<Scalars['Int']>;
  ne: InputMaybe<Scalars['Int']>;
};

export type ModelSizeInput = {
  between: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq: InputMaybe<Scalars['Int']>;
  ge: InputMaybe<Scalars['Int']>;
  gt: InputMaybe<Scalars['Int']>;
  le: InputMaybe<Scalars['Int']>;
  lt: InputMaybe<Scalars['Int']>;
  ne: InputMaybe<Scalars['Int']>;
};

export enum ModelSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ModelStringInput = {
  attributeExists: InputMaybe<Scalars['Boolean']>;
  attributeType: InputMaybe<ModelAttributeTypes>;
  beginsWith: InputMaybe<Scalars['String']>;
  between: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains: InputMaybe<Scalars['String']>;
  eq: InputMaybe<Scalars['String']>;
  ge: InputMaybe<Scalars['String']>;
  gt: InputMaybe<Scalars['String']>;
  le: InputMaybe<Scalars['String']>;
  lt: InputMaybe<Scalars['String']>;
  ne: InputMaybe<Scalars['String']>;
  notContains: InputMaybe<Scalars['String']>;
  size: InputMaybe<ModelSizeInput>;
};

export type Mutation = {
  closeIssue: Maybe<Issue>;
  createIssue: Maybe<Issue>;
  deleteMessage: Maybe<TalkMessage>;
  openTalk: Maybe<Talk>;
  postTalkMessage: Maybe<TalkMessage>;
  registerClient: Maybe<User>;
  registerCounselor: Maybe<User>;
  registerOrganization: Maybe<Organization>;
  updateIssue: Maybe<Issue>;
  updateOwnProfile: Maybe<User>;
};


export type MutationCloseIssueArgs = {
  issueId: Scalars['ID'];
};


export type MutationCreateIssueArgs = {
  input: CreateIssueInput;
};


export type MutationDeleteMessageArgs = {
  input: DeleteTalkMessageInput;
};


export type MutationOpenTalkArgs = {
  input: InputMaybe<OpenTalkInput>;
};


export type MutationPostTalkMessageArgs = {
  input: PostTalkMessageInput;
};


export type MutationRegisterClientArgs = {
  input: RegisterClientInput;
};


export type MutationRegisterCounselorArgs = {
  input: RegisterCounselorInput;
};


export type MutationRegisterOrganizationArgs = {
  input: RegisterOrganizationInput;
};


export type MutationUpdateIssueArgs = {
  input: UpdateIssueInput;
  issueId: Scalars['ID'];
};


export type MutationUpdateOwnProfileArgs = {
  input: UpdateOwnProfileInput;
};

export type OpenTalkInput = {
  firstMessage: InputMaybe<Scalars['String']>;
  issueId: Scalars['ID'];
};

export type Option = {
  disabled: Maybe<Scalars['Boolean']>;
  group: Scalars['String'];
  key: Scalars['String'];
  label: Scalars['String'];
  sortOrder: Maybe<Scalars['Int']>;
};

export type OptionConnection = {
  items: Array<Option>;
  nextToken: Maybe<Scalars['String']>;
};

export type Organization = {
  avatarImageUrl: Maybe<Scalars['String']>;
  category: AccountCategory;
  createdAt: Scalars['AWSDateTime'];
  deleted: Maybe<Scalars['Boolean']>;
  lineAccountId: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  title: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['AWSDateTime']>;
};

export type PostTalkMessageInput = {
  content: InputMaybe<Scalars['String']>;
  talkId: Scalars['ID'];
};

export type Query = {
  getConfigGroup: Maybe<ConfigGroup>;
  getIssueDetail: Maybe<Issue>;
  getOrganization: Maybe<Organization>;
  getOrganizationByLineAccountId: Maybe<Organization>;
  getTalkDetail: Maybe<Talk>;
  getTalkMessages: Maybe<TalkMessageConnection>;
  getUser: Maybe<User>;
  listIssuesByCategory: Maybe<IssueConnection>;
  listOptions: Maybe<OptionConnection>;
  listOwnIssues: Maybe<IssueConnection>;
  me: Maybe<User>;
};


export type QueryGetConfigGroupArgs = {
  group: Scalars['String'];
};


export type QueryGetIssueDetailArgs = {
  issueId: Scalars['ID'];
};


export type QueryGetOrganizationArgs = {
  organizationId: Scalars['ID'];
};


export type QueryGetOrganizationByLineAccountIdArgs = {
  lineAccountId: Scalars['String'];
};


export type QueryGetTalkDetailArgs = {
  issueId: Scalars['ID'];
  talkId: Scalars['ID'];
};


export type QueryGetTalkMessagesArgs = {
  condition: InputMaybe<GetTalkMessageConditionInput>;
  limit: InputMaybe<Scalars['Int']>;
  nextToken: InputMaybe<Scalars['String']>;
};


export type QueryGetUserArgs = {
  userId: Scalars['ID'];
};


export type QueryListIssuesByCategoryArgs = {
  condition: InputMaybe<ListIssuesByCategoryConditionInput>;
  limit: InputMaybe<Scalars['Int']>;
  nextToken: InputMaybe<Scalars['String']>;
};


export type QueryListOptionsArgs = {
  condition: InputMaybe<ListOptionsConditionInput>;
  limit: InputMaybe<Scalars['Int']>;
  nextToken: InputMaybe<Scalars['String']>;
};


export type QueryListOwnIssuesArgs = {
  condition: InputMaybe<ListOwnIssuesConditionInput>;
  limit: InputMaybe<Scalars['Int']>;
  nextToken: InputMaybe<Scalars['String']>;
};

export type RegisterClientInput = {
  affiliation: InputMaybe<Scalars['String']>;
  avatarImageUrl: InputMaybe<Scalars['String']>;
  lineAccountId: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  title: InputMaybe<Scalars['String']>;
};

export type RegisterCounselorInput = {
  affiliation: InputMaybe<Scalars['String']>;
  avatarImageUrl: InputMaybe<Scalars['String']>;
  lineAccountId: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  title: InputMaybe<Scalars['String']>;
};

export type RegisterOrganizationInput = {
  avatarImageUrl: InputMaybe<Scalars['String']>;
  lineAccountId: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  title: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  onPostTalkMessage: Maybe<TalkMessage>;
};


export type SubscriptionOnPostTalkMessageArgs = {
  talkId: Scalars['ID'];
};

export type Talk = {
  counselor: User;
  counselorId: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  deleted: Maybe<Scalars['Boolean']>;
  issueId: Scalars['ID'];
  messages: Maybe<TalkMessageConnection>;
  status: Maybe<Scalars['String']>;
  talkId: Scalars['ID'];
  updatedAt: Maybe<Scalars['AWSDateTime']>;
};


export type TalkMessagesArgs = {
  limit: InputMaybe<Scalars['Int']>;
  nextToken: InputMaybe<Scalars['Int']>;
};

export type TalkMessage = {
  content: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  deleted: Maybe<Scalars['Boolean']>;
  messageId: Scalars['ID'];
  talkId: Scalars['ID'];
  updatedAt: Maybe<Scalars['AWSDateTime']>;
  user: User;
  userId: Scalars['ID'];
};

export type TalkMessageConnection = {
  items: Array<TalkMessage>;
  nextToken: Maybe<Scalars['String']>;
};

export enum TalkStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
  Suggested = 'SUGGESTED'
}

export type UpdateIssueInput = {
  category: Scalars['String'];
  content: InputMaybe<Scalars['String']>;
  subcategory: InputMaybe<Scalars['String']>;
};

export type UpdateOrganizationInput = {
  avatarImageUrl: InputMaybe<Scalars['String']>;
  lineAccountId: InputMaybe<Scalars['String']>;
  name: InputMaybe<Scalars['String']>;
  title: InputMaybe<Scalars['String']>;
};

export type UpdateOwnProfileInput = {
  affiliation: InputMaybe<Scalars['String']>;
  avatarImageUrl: InputMaybe<Scalars['String']>;
  introduction: InputMaybe<Scalars['String']>;
  name: InputMaybe<Scalars['String']>;
  profile: InputMaybe<Scalars['String']>;
  title: InputMaybe<Scalars['String']>;
  welcomed: InputMaybe<Scalars['Boolean']>;
};

export type User = {
  affiliation: Maybe<Scalars['String']>;
  avatarImageUrl: Maybe<Scalars['String']>;
  category: AccountCategory;
  createdAt: Scalars['AWSDateTime'];
  deleted: Maybe<Scalars['Boolean']>;
  introduction: Maybe<Scalars['String']>;
  lineAccountId: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId: Maybe<Scalars['ID']>;
  profile: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  updatedAt: Maybe<Scalars['AWSDateTime']>;
  userId: Scalars['ID'];
  welcomed: Maybe<Scalars['Boolean']>;
};
