import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
} from '@chakra-ui/react';
import { Card } from 'components/common/Card';
import { useAuth } from 'hooks/use-auth';
import useModals from 'hooks/use-modals';
import { useState } from 'react';
import { SiLine } from 'react-icons/si';
import { useNavigate } from 'react-router-dom';

type Props = {
  user: any;
};

const Login = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { user, signIn, signInWith } = useAuth();

  const navigate = useNavigate();
  const { alert } = useModals();

  const handleSignIn = () => {
    setLoading(true);
    signIn(username, password)
      .then(() => navigate('/'))
      .catch((error) => {
        console.error(error);
        setLoading(false);
        alert('ログインに失敗しました。');
      });
  };

  const signInWithLINE = async () => {
    setLoading(true);
    signInWith('LINE');
  };

  const siteTitle = process.env.REACT_APP_SITE_TITLE || 'お悩み相談室';

  return (
    <Flex
      minH={'full'}
      align={'center'}
      justify={'center'}
      bgGradient={'linear(to-r, yellow.100, red.100, pink.100)'}
    >
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'2xl'} color="brand.600">
            {siteTitle}
          </Heading>
        </Stack>
        <Card>
          <Stack spacing={4}>
            <FormControl id="username">
              <FormLabel>ユーザー名</FormLabel>
              <Input
                type="text"
                placeholder=""
                className="input"
                required
                pattern="^([a-zA-Z0-9\-_]{1,})$"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                disabled={loading}
              />
            </FormControl>
            <FormControl id="password">
              <FormLabel>パスワード</FormLabel>
              <Input
                type="password"
                placeholder="********"
                className="input"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={loading}
              />
            </FormControl>
            <Stack spacing={10}>
              <Button
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                isLoading={loading}
                onClick={handleSignIn}
              >
                ログイン
              </Button>
            </Stack>
            <Divider />
            <Stack spacing={10}>
              <Button
                bg={'green.400'}
                color={'white'}
                _hover={{
                  bg: 'green.500',
                }}
                isLoading={loading}
                onClick={signInWithLINE}
                leftIcon={<SiLine />}
              >
                LINE でログイン
              </Button>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Flex>
  );
};

export default Login;
