import { Center, Spinner } from '@chakra-ui/react';
import { SpinnerProps } from '@chakra-ui/spinner';

type Props = {} & SpinnerProps;

export function LoadingIndicator({ ...rest }: Props) {
  return (
    <Center h={'full'} minH={'full'}>
      <Spinner speed="1s" size="xl" thickness='2px' {...rest} />
    </Center>
  );
}
