import { Box, Heading } from '@chakra-ui/react';
import { NoItemIndicator } from 'components/common/NoItemIndicator';
import { useSiteConfig } from 'hooks/use-site-config';

export default function Home() {
  const { homeContents } = useSiteConfig();
  const content = `${homeContents}`;

  return (
    <>
      <Box p={4} m={4} rounded={'lg'} bg={'glass.500'} boxShadow={'lg'}>
        <Heading fontSize={'sm'}>お知らせ</Heading>
        <NoItemIndicator message="お知らせはありません" />
      </Box>
      <Box p={4} m={4} rounded={'lg'} bg={'glass.500'} boxShadow={'lg'}>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Box>
    </>
  );
}
