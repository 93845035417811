import { Avatar, Box, Flex } from '@chakra-ui/react';
import { DateTime } from 'luxon';

type Props = {
  messageId: string;
  message: string;
  mine: boolean;
  userName: string;
  avatarUrl?: string;
  status: string;
  createdAt: string;
};

export function MessageBalloon(props: Props) {
  if (!props.message) {
    return null;
  }

  const timestamp = () => {
    if (!props.createdAt) return '';
    const date = DateTime.fromISO(props.createdAt).toLocal();
    return date.toFormat('HH:mm');
  };

  const isMine = props.mine;

  const avatar = !isMine ? (
    <Avatar
      src={props.avatarUrl || undefined}
      name={props.userName}
      size={'sm'}
    />
  ) : null;

  return (
    <Flex
      maxW={'85%'}
      alignSelf={isMine ? 'flex-end' : 'flex-start'}
      flexDir={isMine ? 'row-reverse' : 'row'}
      p={2}
      scrollSnapAlign={'end'}
    >
      <Box alignSelf={'flex-start'} py={1}>
        {avatar}
      </Box>

      <Box
        bg={isMine ? 'glass.400' : 'glass.600'}
        rounded={'2xl'}
        px={3}
        py={2}
        boxShadow={'md'}
        whiteSpace={'pre-line'}
        mx={1}
      >
        {props.message}
      </Box>
      <Box fontSize={'xs'} alignSelf={'flex-end'}>
        {timestamp()}
      </Box>
    </Flex>
  );
}
