import { Select } from '@chakra-ui/react';
import { Option } from 'API';
import { listOptions } from 'api';
import { useEffect, useState } from 'react';

type Props = {
  id?: string;
  name?: string;
  value?: string;
  onChange: (category: string) => any;
};

export function CategorySelect(props: Props) {
  const [categories, setCategories] = useState<Option[]>([]);

  useEffect(() => {
    let unmounted = false;
    (async () => {
      try {
        const options = await listOptions({ group: 'category' });
        options.sort(
          (a, b) =>
            (a.sortOrder ?? Number.MAX_VALUE) -
            (b.sortOrder ?? Number.MAX_VALUE)
        );
        console.debug('listOptions', options);
        if (!unmounted) {
          if (!props.value) {
            props.onChange?.(options[0].key);
          }
          setCategories(options);
        }
      } catch (error) {
        console.error('listOptions', error);
      }
    })();
    return () => {
      unmounted = true;
    };
  }, []);

  const createCategoryOption = (category: Option) => {
    return (
      <option key={`category-option-${category.key}`} value={category.key}>
        {category.label}
      </option>
    );
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { target } = e;
    const { value } = target;
    props.onChange(value);
  };

  return (
    <Select
      id={props.id || 'category-select'}
      name={props.name || 'category'}
      value={props.value}
      onChange={handleCategoryChange}
    >
      {categories.map(createCategoryOption)}
    </Select>
  );
}
